// sass
import "../scss/app.scss";

// libs
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const axios = require("axios");

// ajax
import ajaxHomeNewsElement from "./ajax/ajax-home-news-element.js";
import ajaxNewsElement from "./ajax/ajax-news-element.js";
import ajaxSubdomainNewsElement from "./ajax/ajax-subdomain-news-element.js";
import ajaxSubdomainVideoNewsElement from "./ajax/ajax-subdomain-video-news-element.js";
import ajaxExchangeElement from "./ajax/ajax-exchange-element.js";
import ajaxPanelPreview from "./ajax/ajax-panel-preview.js";

//modules
import select2 from "./module/select2.js";
import panelActionButtons from "./module/panelActionButtons.js";
import panelToggleCoverSubtitle from "./module/panelToggleCoverSubtitle.js";
import alertConfirm from "./module/alertConfirm.js";
import swiperGalleries from "./module/swiperGalleries.js";
import navbar from "./module/navbar.js";
import asideFilter from "./module/asideFilter.js";
import formAddDelete from "./module/formAddDelete.js";
import uploadImages from "./module/uploadImages.js";
import inputValidation from "./module/inputValidation.js";
import copyToClipboard from "./module/copyToClipboard.js";
import tabsRedirect from "./module/tabsRedirect.js";
import formUtils from "./module/formUtils.js";
import panelAfterLoginPopup from "./module/panelAfterLoginPopup.js";

$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();

    $(".accordion .card .toggle-btn").on("click", function () {
        $(this).toggleClass("toggled");
        $(".toggle-btn")
            .not(this)
            .each(function () {
                $(this).removeClass("toggled");
            });
    });

    $(".cookies .cookies-close").on("click", function () {
        $(".cookies, .cookies .desc").hide();
    });

    function selectChangeUrl() {
        $(".select-change-url").on("change", function () {
            var url = $(this).data("url");
            if (!url) {
                url = $(this).children(":selected").data("url");
            }
            if (!url) {
                url = $(this).val();
            }
            if (url) {
                window.location = url;
            }
            return false;
        });
    }

    //Wywołanie funkcji
    navbar();
    swiperGalleries();
    asideFilter();
    tabsRedirect();
    formAddDelete();
    uploadImages();
    inputValidation();
    copyToClipboard();
    formUtils();
    panelActionButtons();
    panelToggleCoverSubtitle();
    select2();
    selectChangeUrl();
    alertConfirm();
    panelAfterLoginPopup();
    ajaxHomeNewsElement();
    ajaxNewsElement();
    ajaxSubdomainNewsElement();
    ajaxSubdomainVideoNewsElement();
    ajaxExchangeElement();
    ajaxPanelPreview();
});
