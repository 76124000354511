export default function inputValidation() {
    const loader = $(".loader-overlay");
    $("input[type=text]").on("blur", function () {
        var inputValue = $(this).val();
        var minLength = $(this).attr("minLength");
        minLength = parseInt(minLength, 10);
        if (inputValue.length > 0 && inputValue.length < minLength) {
            $(this).addClass("invalid");
        } else {
            $(this).removeClass("invalid");
        }
    });

    $("input[required=required]").on("blur", function () {
        var inputValue = $(this).val();
        var minLength = $(this).attr("minLength");
        minLength = parseInt(minLength, 10);
        if (inputValue == "" || inputValue.length < minLength) {
            $(this).addClass("invalid");
        } else {
            $(this).removeClass("invalid");
        }
    });

    $(".select2").on("change", function () {
        var elements = $(".select2").find(":selected").length;

        if (elements < 1) {
            $(".select2 .selection .select2-selection").addClass("invalid");
        } else {
            $(".select2 .selection .select2-selection").removeClass("invalid");
        }
    });

    $(document).on("blur", "input[type=email]", function () {
        var mail = $(this).val();
        function validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }

        if (validateEmail(mail) !== true) {
            $(this).addClass("invalid");
        } else {
            $(this).removeClass("invalid");
            return;
        }
    });

    //Nie przepuszczenie submita gdy któreś pole formularza ma klase invalid
    $("button[type=submit]").on("click", function (event) {
        if ($("input[type=text][required=required]").val() == "") {
            $(this).addClass("invalid");
        }

        if ($(".upload_tile").length) {
            $("input[type=file]").each(function () {
                if ($(this).prop("required") && $(this).val() === "") {
                    $(this).siblings(".upload_tile").addClass("invalid");
                } else {
                    return;
                }
            });
        } else {
            return;
        }

        var elements = $(".select2").find(":selected").length;

        if (elements < 1) {
            $(".select2 .selection .select2-selection").addClass("invalid");
        } else {
            return;
        }

        var test = $("form").find(".invalid, .invalid-border");
        if (test.length > 0) {
            event.preventDefault();
            event.stopPropagation();
            alert(
                "Formularz zawiera blędy. Popraw je aby móc wysłać formularz."
            );
        } else {
            return;
        }
    });

    $(document).on("submit", "form", function (event) {
        if ($(this).hasClass("no-loader")) {
            loader.addClass("hidden");
        } else {
            loader.removeClass("hidden");
        }

        if ($(".upload_tile").length) {
            if ($("button[type=submit]").attr("disabled").length > -1) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                return;
            }
        }
    });
}
