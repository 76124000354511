export default function panelActionButtons() {
    $('form[name="selected"]').each(function () {
        $(this).on('submit', function (e) {
            let ids = [];

            $(this)
                .closest(".action-buttons")
                .siblings(".ui_tile")
                .each(function () {
                    let input = $(this).find('input[type="checkbox"]');
                    if (input.length && input.is(":checked")) {
                        ids.push(parseInt(input.val()));
                    }
                });

            let input = $(this).find('input[name="selected[ids]"]');
            input.val(ids);
        });
    });
}
