export default function tabsRedirect() {
    if($('.nav-tabs').length) {
        let params = new URLSearchParams(document.location.search.substring(1));
        let type = params.get("type");

        var tabsLink = $(".nav-tabs .nav-item .tab-link").map(function(index, element) {
            return element.href;
        }).get();

        var tabsSubstrLink = tabsLink.map(item => item.substring(item.indexOf('#')));

        var currentLink = '#' + type + '-content';

        let check = tabsSubstrLink.includes(currentLink);

        if(check === true) {
            $('.nav-tabs a[href="' + currentLink + '"]').tab('show');
        }
    }
}