export default function formUtils() {
    function companyContactDataToggle() {
        $(".quixi-checkbox .companyContactData").on("change", function () {
            let value = $(this).val();
            let content = $(this)
                .closest(".quixi-checkbox")
                .siblings(".quixi-checkbox-content");

            console.log(value);
            console.log(content);
            console.log($(this).closest(".quixi-checkbox"));

            content.toggleClass("d-none", value);
        });
    }

    function uiFilterLoader() {
        $(".selects select").on("change", function () {
            $(".loader-overlay").removeClass("hidden");
        });
    }

    function selectAllCheckboxes() {
        $(document).on("click", ".filters input", function () {
            $(".tab-pane.active input[type=checkbox]").prop(
                "checked",
                $(this).prop("checked")
            );
        });

        $(document).on(
            "click",
            ".tab-pane.active input[type=checkbox]",
            function () {
                if (!$(this).prop("checked")) {
                    $(".filters .quixi-checkbox input[type=checkbox]").prop(
                        "checked",
                        false
                    );
                } else if (
                    $(".tab-pane.active input[type=checkbox]").not(":checked")
                        .length -
                        1 ===
                    0
                ) {
                    $(".filters .quixi-checkbox input[type=checkbox]").prop(
                        "checked",
                        true
                    );
                }
            }
        );
    }

    function textareaEditor() {
        if ($(".editor").length) {
            ClassicEditor.create(document.querySelector(".editor"))
                .then((editor) => {
                    console.log(editor);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    companyContactDataToggle();
    uiFilterLoader();
    selectAllCheckboxes();
    textareaEditor();
}
