export default function navbar() {
    $(".login .dropdown-toggle").on("mouseenter", function () {
        $(".login .dropdown-menu").addClass("show");
    });

    $(".login .dropdown-toggle, .login .dropdown-menu").on(
        "mouseleave",
        function () {
            $(this).removeClass("show");
        }
    );

    function menuToggle() {
        var bar = $(".presskit-name");
        var menu = $(".menu");
        var intro = $(".navbar.intro");

        if ($(".navbar-presskit").length) {
            if (window.innerWidth >= 768 && window.innerWidth < 1140) {
                $("body").css("padding-top", "275px");
            } else {
                $("body").css("padding-top", "330px");
            }

            function presskitMenu() {
                if (window.innerWidth < 1140) {
                    var lastScrollTop = 0;
                    $(window).on('scroll', function (event) {
                        var st = $(this).scrollTop();
                        if (st >= 250) {
                            bar.addClass("scrolled");
                            if (st > lastScrollTop) {
                                menu.addClass("scrolled");
                                menu.removeClass("scrolled-up");
                                intro.addClass("scrolled");
                            } else {
                                menu.removeClass("scrolled");
                                menu.addClass("scrolled-up");
                                intro.removeClass("scrolled");
                            }
                            lastScrollTop = st;
                        } else {
                            bar.removeClass("scrolled");
                            menu.removeClass("scrolled");
                            menu.removeClass("scrolled-up");
                            intro.removeClass("scrolled");
                        }
                    });
                } else {
                    $(window, document.body).on("wheel touchmove", function (e) {
                        var delta = e.originalEvent.deltaY;
                        scroll = window.pageYOffset;

                        if (scroll >= 127) {
                            bar.addClass("scrolled");
                            if (delta > 0) {
                                //scroll down
                                menu.addClass("scrolled");
                                intro.addClass("scrolled");
                                menu.removeClass("scrolled-presskit");
                            } else {
                                //scroll up
                                menu.removeClass("scrolled");
                                intro.removeClass("scrolled");
                                menu.addClass("scrolled-presskit");
                            }
                        } else {
                            menu.removeClass("scrolled");
                            menu.removeClass("scrolled-presskit");
                            intro.removeClass("scrolled");
                            bar.removeClass("scrolled");
                        }
                    });
                }
            }
            presskitMenu();
        } else {
            if ($(window).width() < 1140) {
                var lastScrollTop = 0;
                $(window).on('scroll', function (event) {
                    var st = $(this).scrollTop();
                    if (st >= 127) {
                        if (st > lastScrollTop) {
                            menu.addClass("scrolled");
                            intro.addClass("scrolled");
                        } else {
                            menu.removeClass("scrolled");
                            intro.removeClass("scrolled");
                        }
                        lastScrollTop = st;
                    } else {
                        menu.removeClass("scrolled");
                        intro.removeClass("scrolled");
                    }
                });
            } else {
                $(window, document.body).on("wheel touchmove", function (e) {
                    var delta = e.originalEvent.deltaY;
                    scroll = window.pageYOffset;

                    if (scroll >= 127) {
                        if (delta > 0) {
                            //scroll down
                            menu.addClass("scrolled");
                            intro.addClass("scrolled");
                        } else {
                            //scroll up
                            menu.removeClass("scrolled");
                            intro.removeClass("scrolled");
                        }
                    } else {
                        menu.removeClass("scrolled");
                        intro.removeClass("scrolled");
                    }
                });
            }
        }
    }

    function searchForm() {
        $(".navbar .search-btn").on("click", function () {
            $(".search .overlay").fadeIn();
            $(".search .search-form").delay(300).slideDown();
        });

        $(".search .search-form .search-close").on("click", function () {
            $(".search .overlay").fadeOut();
            $(".search .search-form").slideUp();
        });
    }

    function mobileLogin() {
        if (window.innerWidth < 1140) {
            $(".navbar .options .mobile-login-btn").on("click", function () {
                $(".mobile-log .overlay").fadeIn();
                $(".mobile-log .login-select").delay(300).slideDown();
            });

            $(".navbar .options .mobile-logged-btn").on("click", function () {
                $(".mobile-logged-menu .overlay").fadeIn();
                $(".mobile-logged-menu .login-select").delay(300).slideDown();
            });

            $(".mobile-log .login-select .search-close").on("click", function () {
                $(".mobile-log .overlay").fadeOut();
                $(".mobile-log .login-select").slideUp();
            });

            $(".mobile-logged-menu .login-select .search-close").on("click", function () {
                $(".mobile-logged-menu .overlay").fadeOut();
                $(".mobile-logged-menu .login-select").slideUp();
            });
        }
    }

    //Wywołanie funkcji
    menuToggle();
    searchForm();
    mobileLogin();
}