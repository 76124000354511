const axios = require("axios");
import showLoader from "../module/showLoader.js";

function onClickCommon(self, container) {
    showLoader(true);
    let tagsIds = [];
    $(container)
        .find(".ajax-filters .categories ul li.active")
        .each(function () {
            tagsIds.push($(this).data("id"));
        });

    let url = $(container).data("url");
    let params = {
        c: $(container).data("c").toString().split(","),
        lang: $(container).data("lang"),
        tagsIds: tagsIds,
        contentPartial: $(container).data("content-partial"),
        paginatorPage: $(self).data("paginator-page"),
        paginatorPerPage: $(container).data("paginator-per-page"),
        paginationPartial: $(container).data("pagination-partial"),
        partialParams: {
            route: $(container).data("route"),
            route_params: $(container).data("route-params"),
        },
    };

    axios
        .get(url, {
            params: params,
            headers: { "X-Requested-With": "XMLHttpRequest" },
        })
        .then(function (response) {
            $(container)
                .find(".ajax-content")
                .fadeOut("slow", function () {
                    $(this).html(response.data.html.content);
                    $(this).fadeIn("slow", function () {
                        ajaxSubdomainNewsElementPagination(container);
                    });
                });

            $(container)
                .find(".ajax-pagination")
                .fadeOut("slow", function () {
                    $(this).html(response.data.html.pagination);
                    $(this).fadeIn("slow", function () {
                        ajaxSubdomainNewsElementPagination(container);
                    });
                });
        })
        .catch(function (error) {
            console.log(error);
        })
        .then(function () {
            // always executed
            showLoader(false);
        });
}

function ajaxSubdomainNewsElementPagination(container) {
    $(container)
        .find(".ajax-pagination .pagination .page-link")
        .on("click", function (e) {
            e.preventDefault();
            onClickCommon(this, container);
            $(window).scrollTop(0);
        });
}

export default function ajaxSubdomainNewsElement() {
    $(".ajax-subdomain-news-element").each(function () {
        let container = this;
        $(this)
            .find(".ajax-filters .categories ul li")
            .each(function () {
                $(this).on("click", function () {
                    onClickCommon(this, container);
                });
            });

        $(this)
            .find(".ajax-filters .categories button")
            .on("click", function () {
                onClickCommon(this, container);
            });

        ajaxSubdomainNewsElementPagination(container);
    });
}
