import Swiper from "swiper/swiper-bundle.min.js";

export default function swiperGalleries() {
    function videoSwiper() {
        var video = new Swiper(".video .swiper-container", {
              direction: "horizontal",
              loop: false,
              slidesPerView: 1,

              breakpoints: {
                  768: {
                      slidesPerView: 2,
                  },

                  1024: {
                      slidesPerView: 3,
                  },
              },

              pagination: {
                  el: ".swiper-pagination",
                  clickable: true,
              },
          });

          if($('.video .swiper-container').length) {
              let slidesCount = video.slides.length;

              if(window.innerWidth > 992 && slidesCount <= 3) {
                  $('.video .swiper-container .swiper-pagination').css('display', 'none');
              }
          }
      }

      function galleryScroll() {
        $('.download-media .tiles a').on('click', function(e) {
            e.preventDefault();
            $(window).scrollTop($('.gallery').offset().top - 95);
            $('.navbar').addClass('scrolled');
        });
    }

    function newsSwiper() {
        var gallery = new Swiper(".gallery .swiper-container", {
            direction: "horizontal",
            loop: false,
            slidesPerView: 1,
            centeredSlides: true,

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },

            on: {
                init: activeSlide,
                slideChange: activeSlide
            }
        });

        function activeSlide() {
            var active = this.realIndex;
            var tiles = $(".mini-gallery .miniature").map(function(index, element) {
                return element;
            }).get();

            var activeTile = tiles[active];

            activeTile.classList.add('currentTile');

            for (let i = 0; i < tiles.length; i++) {
                if(tiles[i] !== activeTile) {
                    tiles[i].classList.remove('currentTile');
                }
            }
        }

        function downloadSelected() {
            var chkbx = $(".mini-gallery .miniature .overlay-download input");

            chkbx.on("click", function () {
                $(this).toggleClass("checked");
            });

            $(".download_selected").on("click", function () {
                var photo_addr = $(".checked").siblings(".download");

                var links = photo_addr
                    .map(function (index, element) {
                        return element.href;
                    })
                    .get();

                for (var i = 0; i < links.length; i++) {
                    axios({
                        url: links[i],
                        method: "GET",
                        responseType: "blob",
                    }).then((response) => {
                        var a = document.createElement("a");
                        var url = window.URL.createObjectURL(response.data);
                        a.href = url;
                        a.download = response.config.url.split("/").pop();
                        document.body.append(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    });
                }
            });
        }

        function downloadAll() {
            $(".download_all").on("click", function () {
                var photo_addr = $(".download");

                var links = photo_addr
                    .map(function (index, element) {
                        return element.href;
                    })
                    .get();

                for (var i = 0; i < links.length; i++) {
                    axios({
                        url: links[i],
                        method: "GET",
                        responseType: "blob",
                    }).then((response) => {
                        var a = document.createElement("a");
                        var url = window.URL.createObjectURL(response.data);
                        a.href = url;
                        a.download = response.config.url.split("/").pop();
                        document.body.append(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    });
                }
            });
        }

        downloadSelected();
        downloadAll();
    }

    function miniatureSelect() {
        $(".miniature input").on("click", function () {
            if ($("input").is(":checked")) {
                $(".mini-gallery .overlay-download").addClass("selected");
            } else {
                $(".mini-gallery .overlay-download").removeClass("selected");
            }
        });
    }

    //Wywołanie funkcji
    videoSwiper();
    galleryScroll();
    newsSwiper();
    miniatureSelect();
}
