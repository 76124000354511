export default function panelToggleCoverSubtitle() {
    $(document).on("click", "form .images .upload_image_subtitle button", function(e) {
            e.preventDefault();

            $(this)
                .closest("form")
                .find(".upload_image_subtitle > button")
                .each(function () {
                    $(this).css("font-weight", "initial");
                    $(this).css("color", "initial");
                });

            $(this).css("font-weight", "bold");
            $(this).css("color", "#CC0C2F");

            let current = $(this)
                .closest("form")
                .find(
                    '.images-subtitles > div[data-key="' +
                        $(this).data("key") +
                        '"]'
                );

            let all = $(this).closest("form").find(".images-subtitles > div");

            all.each(function () {
                if (this !== current[0]) {
                    $(this).slideUp("slow");
                }
            });

            current.slideToggle("slow");
        });
}
