export default function formAddDelete() {
    function copyElementAndCleanInput(element) {
        let copy = element.clone();
        let input = copy.is("input") ? copy : copy.find("input");
        let nextKey = $('.templates').attr('data-next-key');
        if (input.length) {
            input.val("");
            input.prop("disabled", false);
            input.attr("name", function () {
                var attrval = $(this).attr("name");
                if(attrval.indexOf('#key#') >= 0) {
                    attrval = attrval.replace('#key#', nextKey);
                } else {
                    attrval = attrval.replace(/\[(\d+)\]/, function (fullMatch, n) {
                        return "[" + (Number(n) + 1) + "]";
                    });
                }
                return attrval;
            });
            input.attr("id", function () {
                var attrval = $(this).attr("id");
                if(attrval.indexOf('#key#') >= 0) {
                    attrval = attrval.replace('#key#', nextKey);
                } else {
                attrval = attrval.replace(/(\d+)/, function (fullMatch, n) {
                    return Number(n) + 1;
                });
            }
                return attrval;
            });

            let label = input.siblings("label");
            if (label.length) {
                label.find('.miniature img').attr('src', function() {
                    var image = $(this).attr('src');
                    var miniature = '/layout/default/dist/gfx/icons/photo_upload.png';
                    image =  image.replace(image, miniature);
                    return image;
                });

                label.attr("for", function () {
                    var attrval = $(this).attr("for");
                    if(attrval.indexOf('#key#') >= 0) {
                        attrval = attrval.replace('#key#', nextKey);
                    } else {
                        attrval = attrval.replace(/(\d+)/, function (fullMatch, n) {
                            return Number(n) + 1;
                        });
                    }
                    return attrval;
                });
            }

            let inputParent = input.parent(".form-group");
            let dataKey = inputParent.parent('.sub');
            if (dataKey.length) {
                dataKey.attr("data-key", function () {
                    var attrval = $(this).attr("data-key");
                    if(attrval.indexOf('#key#') >= 0) {
                        attrval = attrval.replace('#key#', nextKey);
                    } else {
                        attrval = attrval.replace(/(\d+)/, function (fullMatch, n) {
                            return Number(n) + 1;
                        });
                    }
                    return attrval;
                });
            }

            let inputParent_2 = input.parent(".upload_image");
            let dataKeyBtn = inputParent_2.siblings('.upload_image_subtitle').find('.dataBtn');
            if (dataKeyBtn.length) {
                dataKeyBtn.attr("data-key", function () {
                    var attrval = $(this).attr("data-key");
                    if(attrval.indexOf('#key#') >= 0) {
                        attrval = attrval.replace('#key#', nextKey);
                    } else {
                        attrval = attrval.replace(/(\d+)/, function (fullMatch, n) {
                            return Number(n) + 1;
                        });
                    }
                    return attrval;
                });
            }

            if(inputParent_2.length) {
                inputParent_2.attr("data-key", function () {
                    var attrval = $(this).attr("data-key");
                    if(attrval.indexOf('#key#') >= 0) {
                        attrval = attrval.replace('#key#', nextKey);
                    } else {
                        attrval = attrval.replace(/(\d+)/, function (fullMatch, n) {
                            return Number(n) + 1;
                        });
                    }
                    return attrval;
                });
            }
        }
        return copy;
    }

    function addMorePhotos() {
        $(".upload-more button").on("click", function () {
            let element = $('.templates .image .form-group');
            element = copyElementAndCleanInput(element);
            $(this)
                .closest(".upload-more")
                .siblings(".images")
                .find(".form-row")
                .append(element);
            element.find('.upload_image_subtitle').append("<button type='button' class='del-more'>-</button>");

            let elementID = $('.templates .image-id input');
            elementID = copyElementAndCleanInput(elementID);
            $(this)
                .closest(".upload-more")
                .siblings(".images-ids")
                .find(".form-row .form-group")
                .append(elementID);

            let elementSubtitle = $('.templates .image-subtitle .sub');
            elementSubtitle = copyElementAndCleanInput(elementSubtitle);
            $(this)
                .closest(".upload-more")
                .siblings(".images-subtitles")
                .append(elementSubtitle);

            let nextKey = $('.templates').attr('data-next-key');
            nextKey = parseInt(nextKey, 10);
            nextKey++;
            $('.templates').attr('data-next-key', nextKey);
        });
    }

    function addNumber() {
        $(".upload-more.number button").on("click", function () {
            let element = $(this)
                .closest(".upload-more.number")
                .siblings(".number-multi")
                .children()
                .last();
            element = copyElementAndCleanInput(element);
            $(this)
                .closest(".upload-more.number")
                .siblings(".number-multi")
                .append(element);
            element.append("<button type='button' class='del-more'>-</button>");
        });
    }

    function addEmail() {
        $(".upload-more.email button").on("click", function () {
            let element = $(this)
                .closest(".upload-more.email")
                .siblings(".email-multi")
                .children()
                .last();
            element = copyElementAndCleanInput(element);
            $(this)
                .closest(".upload-more.email")
                .siblings(".email-multi")
                .append(element);
            element.append("<button type='button' class='del-more'>-</button>");
        });
    }

    function deleteInput() {
        $(document).on('click', '.del-more', function() {
            $(this).parent('.input-group').remove();

            let imageSelect = $(this).parent('.upload_image_subtitle');
            let dataKey = $(this).siblings('.dataBtn').attr('data-key');

            imageSelect.parent('.form-group').remove();
            $('.images-subtitles').find(`[data-key='${dataKey}']`).remove();
            $('.images-ids .form-row .form-group').find(`[data-key='${dataKey}']`).remove();
        });
    }

    function addCategory() {
        $(".upload-more.category button").on("click", function () {
            let element = $(".category-multi").children().last();
            element = copyElementAndCleanInput(element);
            $(".category-multi").append(element);
        });
    }

    //Wywołanie funkcji
    addMorePhotos();
    addCategory();
    addNumber();
    addEmail();
    deleteInput();
}
