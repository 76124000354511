import cookieSystem from "./cookieSystem.js";

export default function panelAfterLoginPopup() {
    function show() {
        let cs = new cookieSystem();
        let cookie = cs.getCookie("after-login-popup");
        if (cookie) {
        } else {
            cs.setCookie("after-login-popup", true, 365);
            let afterLoginPopup = $(".after-login-popup");
            if (afterLoginPopup.length) {
                afterLoginPopup.modal("show");
            }
        }
    }

    show();
}
