export default function copyToClipboard() {
    var $temp = $("<input>");
    var $url = $(location).attr('href');

    $('.copyLink').on('click', function() {
        $("body").append($temp);
        $temp.val($url).select();
        document.execCommand("copy");
        $temp.remove();
        alert('Link skopiowany!');
    })
}