export default function asideFilter() {
    function filter() {
        if ($(window).width() < 1170) {
            $(".ajax-filters .toggle").on("click", function () {
                $(".categories").slideToggle();
                $('.ajax-filters').toggleClass('show');
            });
        } else {
            $(".ajax-filters").on("mouseover", function () {
                $(this).addClass("show");
            });

            $(".ajax-filters").on("mouseout", function () {
                $(this).removeClass("show");
            });
        }

        if($('.ajax-container').length) {
            $(document).on('scroll', window, function() {
                if($(window).scrollTop() >= $('.ajax-container').offset().top + $('.ajax-container').outerHeight() - window.innerHeight + 520 / 2) {
                    $('.ajax-container .ajax-filters').addClass('sticky');
                } else {
                    $('.ajax-container .ajax-filters').removeClass('sticky');
                }
            });
        }
    }

    function filterSelect() {
        $(".ajax-filters li").on("click", function () {
            $(this).toggleClass("active");
        });

        $(".ajax-filters button").on("click", function () {
            $(".ajax-filters li").removeClass("active");
        });
    }

    //Wywołanie funkcji
    filter();
    filterSelect();
}