import "select2/dist/js/select2.min.js";

export default function select2() {
    $(".select2").each(function () {
        let lang = $(this).data("lang");

        $(this).select2({
            language: {
                maximumSelected: function (e) {
                    let textBefore = "";
                    let textAfter = "";

                    switch (lang) {
                        case "pl":
                            textBefore = "Możesz wybrać tylko ";
                            textAfter = " elementy.";
                            break;
                        case "en":
                        default:
                            textBefore = "You can choose only ";
                            textAfter = " elements.";
                            break;
                    }

                    let text = textBefore + e.maximum + textAfter;
                    return text;
                },
                noResults: function (e) {
                    let text = "";

                    switch (lang) {
                        case "pl":
                            text = "Nie znaleziono wyników.";
                            break;
                        case "en":
                        default:
                            text = "No results found.";
                            break;
                    }

                    return text;
                },
            },
        });
    });

    $(".select2-create-if-not-exists").each(function () {
        $(this).select2({
            tags: true,
        });
    });

    $('.newsletter-form .form-desc form select').select2({
        minimumResultsForSearch: Infinity
    });
}
