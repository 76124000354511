export default function alertConfirm() {
    $("a.alert-confirm").on("click", function (e) {
        e.preventDefault();

        var answer = confirm($(this).data("alert"));
        if (answer) {
            window.location = $(this).attr("href");
        }
    });
}
