const axios = require("axios");

export default function uploadImages() {
    function ajaxUploadImages() {
        var loading = 0;
        $(document).on("change", ".ajax-upload", function (e) {
            // turn on loader
            let tile_loader = $(this)
                .parent(".upload_image")
                .find(`.upload_tile .loader_bg`);
            $(tile_loader).removeClass("d-none");
            loading++;
            disableBtn();

            if (this.files && this.files[0]) {
                let url = "/api/app/upload-file";

                // prepare post data
                let formData = new FormData();
                formData.append("file", this.files[0]);
                formData.append(
                    "formId",
                    this.closest("form").querySelector(
                        'input[type="hidden"][data-formid]'
                    ).value
                );
                formData.append("type", this.dataset.type);
                formData.append("filterType", this.dataset.filterType);
                formData.append(
                    "coverId",
                    parseInt(this.closest(".upload_image").dataset.key)
                );

                // prepare elements to modify after ajax request
                let img = $(this)
                    .siblings(".upload_tile")
                    .find(".miniature img");
                let p = $(this).siblings(".upload_tile").find(".miniature p");

                // ajax request
                axios
                    .post(url, formData, {
                        headers: {
                            "X-Requested-With": "XMLHttpRequest",
                        },
                    })
                    .then(function (response) {
                        // replace img with response
                        let imgSrc = response.data.thumbnail
                            ? response.data.thumbnail
                            : "/layout/default/dist/gfx/icons/photo_upload.png";
                        img.attr("src", imgSrc);

                        // remove p if necessary (main img)
                        if (p) {
                            p.css("display", "none");
                        }

                        // remove loader
                        $(tile_loader).addClass("d-none");
                        tile_loader
                            .parent(".upload_tile")
                            .removeClass("invalid");
                        loading--;
                        disableBtn();
                    })
                    .catch(function (error) {
                        // remove loader
                        $(tile_loader).addClass("d-none");
                        loading--;
                        disableBtn();
                        $(tile_loader)
                            .parent(".upload_tile")
                            .addClass("invalid");

                        if (error.response) {
                            let message = error.response.data.message;
                            let status = error.response.status;

                            if (status == 500) {
                                alert(
                                    "Wystąpił błąd po stronie serwera. Spróbuj ponownie później"
                                );
                            } else {
                                alert(message);
                            }
                        }
                    });

                // remove file from input
                this.value = "";

                // remove required from input if cover
                if (formData.get("type") == "cover") {
                    this.removeAttribute("required");
                }
            } else {
                // remove loader
                $(tile_loader).addClass("d-none");
            }

            function disableBtn() {
                if (loading <= 0) {
                    $("button[type=submit]").removeAttr("disabled");
                } else {
                    $("button[type=submit]").prop("disabled", true);
                }
            }
        });
    }

    function normalUploadImages() {
        var loading_normal = 0;
        $(document).on("change", ".normal-upload", function (e) {
            imageValidation($(this));
            loading_normal++;
            disableBtnNormal();
            var reader = new FileReader();
            const defaultImage =
                "/layout/default/dist/gfx/icons/photo_upload.png";
            reader.img = $(this)
                .siblings(".upload_tile")
                .find(".miniature img");
            if (this.files[0].type === "image/tiff") {
                reader.onload = function (e) {
                    this.img.attr("src", defaultImage);
                    this.img.after("<p>Zdjęcie załadowane poprawnie.</p>");
                    loading_normal--;
                    disableBtnNormal();
                };
            } else {
                reader.onload = function (e) {
                    this.img.attr("src", e.target.result);
                    loading_normal--;
                    disableBtnNormal();
                };
            }
            reader.readAsDataURL(this.files[0]);
            if ($(this).siblings(".upload_tile").find(".miniature p")) {
                $(this)
                    .siblings(".upload_tile")
                    .find(".miniature p")
                    .css("display", "none");
            }

            function disableBtnNormal() {
                if (loading_normal <= 0) {
                    $("button[type=submit]").removeAttr("disabled");
                } else {
                    $("button[type=submit]").prop("disabled", true);
                }
            }
        });
    }

    function imageValidation(element) {
        var inputValues = element.attr("mimetypesimploded");
        var fileExtension = inputValues.split(", ");
        var values = element.attr("extensionsimploded");
        var splitValues = values.split(", ");

        var minHeight = element.attr("minheight");
        minHeight = parseInt(minHeight, 10);
        var minWidth = element.attr("minwidth");
        minWidth = parseInt(minWidth, 10);

        //Walidacja typu pliku
        function fileTypeValid(event) {
            var input = event.target;
            var files = input.files;
            var type = files[0].type;

            if ($.inArray(type, fileExtension) == -1) {
                alert("Dozwolone są tylko formaty: " + splitValues.join(", "));
                element.val("");
                element.siblings(".upload_tile").addClass("invalid");
            } else {
                element.siblings(".upload_tile").removeClass("invalid");
            }
        }

        //Walidacja wymiarów pliku
        function imageSizeValid(event) {
            let tile = element.parent(".upload_image").find(`.upload_tile`);
            let tile_input = element.parent(".upload_image").find(`input`);
            var reader = new FileReader();
            var input = event.target;

            //Read the contents of Image File.
            reader.readAsDataURL(input.files[0]);
            reader.onload = function (e) {
                //Initiate the JavaScript Image object.
                var image = new Image();

                //Set the Base64 string return from FileReader as source.
                image.src = e.target.result;

                //Validate the File Height and Width.
                image.onload = function () {
                    var height = this.height;
                    var width = this.width;
                    if (width < minWidth || height < minHeight) {
                        alert(
                            `Wybrany obraz ma niewłaściwe rozmiary. Minimalny rozmiar to ${minWidth}x${minHeight}`
                        );
                        $(tile).addClass("invalid");
                        $(tile_input).val("");
                    } else {
                        $(tile).removeClass("invalid");
                    }
                };
            };
        }

        fileTypeValid(event);
        imageSizeValid(event);
    }

    ajaxUploadImages();
    normalUploadImages();
}
